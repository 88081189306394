<template>
    <div class="subExpertGuidance wrap1148">
        <div class="expert-infors">
            <img class="hd-infor" :src="expert.img" alt="" width="120" height="120" v-if="expert">
            <img class="hd-infor" src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" alt="" width="120" height="120" v-else>
            <div class="txt-infors">
                <h4>{{expert ? expert.name : '暂无编辑'}}</h4>
                <p>{{expert ? expert.label : '暂无编辑'}}</p>
            </div>
        </div>
        <div class="expert-models">
            <ol class="models-list">
                <li :class="{'active': index==1 }" @click="changeIndex(1)">简介</li>
                <li :class="{'active': index==2 }" @click="changeIndex(2)">视频</li>
                <li :class="{'active': index==3 }" @click="changeIndex(3)">文章</li>
                <li :class="{'active': index==4 }" @click="changeIndex(4)">留言</li>
            </ol>
            <div class="content-list">
                <div class="des-infors" v-show="index==1">
                    {{expert ? expert.des : '暂无编辑'}}
                </div>
                <div v-show="index==2">
                    <div class="vedio-play wrap1200">
                        <div class="vedio-boxs posra">
                            <video id="myVideo" class="vedioPlay" muted="muted" :src="'https://oss.eyyb.vip/'+playInfo.videoPath" width="570" height="320" controls="true">
                                您的浏览器不支持 video 标签。
                            </video>
                            <div class="bg-vedioCover"></div>
                            <ul class="vedio-box">
                                <li  v-for="(item, index) in videoContentList" :key=item.id :class="{'active': item.id == playId}" @click="changeVedio(item)"><i :class="['iconfont icon-bofang', {'icon-zhuzhuangtu': item.id== playId}]"> {{item.name}}</i></li>
                                <!-- <li><i class="iconfont icon-bofang"> 互联网与教师发展（2）</i></li> -->
                            </ul>
                            <div class="warningTips-cover c-shadows" v-show="currentVip < minVip || !name">
                                <div class="bg-shadows"></div>
                                <div class="txt-infos">
                                    <div v-show="currentVip < minVip && name">
                                        <p>需要Lv.{{minVip}} 等级才能播放！</p>
                                        <p>（您现在的等级是Lv.{{currentVip}}）</p>
                                    </div>
                                    <div v-show="!name" class="cursor" @click="linkTologin">
                                        <p>需要登录才能播放！</p>
                                        <p>去登录</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pages-list teachingResearch" v-show="index==3">
                    <ul class="list-row">
                        <li v-for="(item,index) in articContentList">
                            <a href="javascript:;" class="li-left" @click="linkToDetails(item)">
                                <img :src="'https://oss.eyyb.vip/'+item.bgkImg" alt="" width="282" height="158">
                            </a>
                            <div class="list-infos">
                                <h5><a href="javascript:;" @click="linkToDetails(item)">{{item.name}}</a></h5>
                                <div class="size-16 posra">
                                    <p><i class="s-tips s-tips-blue" v-if="item.labels">{{item.labels}} </i><span>{{item.resourceFrom}} | {{item.publishDate ? item.publishDate : '2020-02-20'}}</span></p>
                                    <i class="el-icon-view"> {{item.playTimes}}次</i>
                                </div>
                            </div>
                        </li>
                        <!-- <li>
                            <a href="#" class="li-left"><img src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="" width="282" height="158"></a>
                            <div class="list-infos">
                                <h5><a href="#">关注要素发展，兼顾工具人文，走进语文学习胡百花深处</a></h5>
                                <div class="size-16 posra">
                                    <p><i class="s-tips s-tips-blue">资讯</i> <span>语文报 | 2019-01-01</span> <i>教材解读</i></p>
                                    <i class="el-icon-view"> 1561次</i>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                </div>
                <div v-show="index==4" class="expert-forms">
                    <el-form ref="expertForm" :model="expertForm" label-width="100px">
                        <el-form-item label="标题">
                          <el-input ref="name" v-model="expertForm.name"></el-input>
                        </el-form-item>
                        <el-form-item label="内容">
                            <el-input ref="content" :autosize="{ minRows: 5, maxRows: 10}" type="textarea" v-model="expertForm.desc"></el-input>
                        </el-form-item>
                        <p class="u-center"><a href="javascript:;" @click="saveComments(name)">提交</a></p>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scope>
    @import '@/assets/css/pages/teachingResearch.scss';
    @import '@/assets/css/pages/vedioPlay.scss';
</style>

<script>
    import {mapGetters} from "vuex";
    import {getLocalStorage, getVipMin, getVipLevel, getNowCookie} from '@/utils/commonBase';
    export default {
        data () {
            return {
                index: 2,
                expertForm: {
                    name: '',
                    desc: ''
                },
                videoContentList: [],
                articContentList: [],
                playId: '',
                playInfo: {},
                minVip: 0, // video播放最小vip
                currentVip: 0, // 当前vip
                expert: {}, // 专家信息
                expertList: {
                    'BxgTFdxRyzUT5S1dzpRNTf': {
                        des: '教育部课程教材研究所研究员、人民教育出版社编审、教育部《义务教育英语课程标准（2011年版）》专家组核心成员',
                        name: '李静纯',
                        img: require('../../../assets/images/expert/ljc.png'),
                        label: '教育部课程教材研究所研究员、人民教育出版社编审、教育部《义务教育英语课程标准（2011年版）》专家组核心成员'
                    },
                    'QdtdryhbjTcKjvf5i8mnoj': {
                        des: '资深小学英语教研专家、全国基础外语教育研究培训中心理事、湖南省中小学教育外语教学研究专业委员会副理事长',
                        name: '刘建清',
                        img: require('../../../assets/images/expert/ljq.png'),
                        label: '资深小学英语教研专家、全国基础外语教育研究培训中心理事、湖南省中小学教育外语教学研究专业委员会副理事长'
                    },
                    'KfJSVUQM6b9Qxg3ug3CgLJ': {
                        des: '人民教育出版社编审、义务教育新课标实验教科书小学英语教材主编',
                        name: '郝建萍',
                        img: require('../../../assets/images/expert/hjp.png'),
                        label: '人民教育出版社编审、义务教育新课标实验教科书小学英语教材主编'
                    },
                    '9t35W6oeUF41LZNL48CJi7': {
                        des: '国家基础教育实验中心外语教育研究中心常务理事、《基础教育外语教学研究》杂志副主编、《外语教学法研究》杂志执行主编',
                        name: '李永大',
                        img: require('../../../assets/images/expert/lyd.png'),
                        label: '国家基础教育实验中心外语教育研究中心常务理事、《基础教育外语教学研究》杂志副主编、《外语教学法研究》杂志执行主编'
                    }
                }
            }
        },
        computed: {
            ...mapGetters([
                'name'
            ]),
        },
        watch: {
            name() {
                var audio = document.getElementById("myVideo");
                audio.pause();
            }
        },
        created () {
            this.loadInfos();
            this.minVip = getVipMin(getLocalStorage(this.$route.params.id));
            this.currentVip = getVipLevel(getLocalStorage('memberCode'));
        },
        methods: {
            saveComments(name) {
                if(!name) {
                    this.$message.error('请先登录后再提交。');
                    return;
                }
                if(!this.expertForm.desc.trim().length) {
                    this.$message.error('留言内容不能为空哦。');
                    this.$refs.content.focus();
                    return;
                }
                if(!this.expertForm.name.trim().length) {
                    this.$message.error('留言标题不能为空哦。');
                    this.$refs.name.focus();
                    return;
                }
                this.$http({
                    method: 'post',
                    url: '/api/yyb/v1/expModule/web/saveComments',
                    data: {
                        moduleId: this.$route.params.id,
                        name: this.expertForm.name,
                        content: this.expertForm.desc
					}
                }).then(res => {
                    console.log('res', res)
                    if(res.data.success) {
                        this.$message.success('操作成功。')
                    } else {
                        this.$message.success(res.data.msg)
                    }
                }).catch(error => {
                    console.log('error', error)
                })
            },
            changeIndex(index) {
                if(index == this.index) return;
                this.index = index;
                var audio = document.getElementById("myVideo");
                audio.pause();
            },
            loadInfos() { // 加载列表内容
                this.$http({
                    method: 'get',
                    url: '/api/yyb/v1/expModule/web/queryEntryPage',
                    params: {
                        // seriesId: this.$route.query.seriesId,
                        // id: this.$route.params.id,
                        moduleId: this.$route.params.id,
                        pageNo: 1,
                        pageSize: 100
					}
                }).then(res => {
                    console.log('res', res);
                    this.expert = this.expertList[this.$route.params.id];
                    this.videoContentList = res.data.obj.videoPage.list;
                    this.articContentList = res.data.obj.articlePage.list;
                    this.changeVedio(this.videoContentList[0])
                }).catch(error => {
                    console.log('error', error)
                })
            },
            changeVedio(row, boolean) {
                this.playId = row.id;
                this.playInfo = {
                    videoPath: row.videoPath,
                    // videoPath: 'https://oss.eyyb.vip/' + row.videoPath,
                    remarks: row.remarks ? row.remarks : '暂无简介',
                    name: row.name,
                    playTimesZh: row.playTimesZh,
                    time:row.time,
                    updateDate: row.updateDate
                }
                var audio = document.getElementById("myVideo");
                var playPromise = audio.play();
                if (playPromise) {
                    playPromise.then(() => {
                        // 音频加载成功
                        // 音频的播放需要耗时
                        setTimeout(() => {
                            // 后续操作
                            console.log("done");
                            audio.play();
                        }, audio.duration * 1000); // audio.duration 为音频的时长单位为秒
                    }).catch((e) => {
                        console.log("Operation is too fast, audio play fails");
                    });
                }
                if(!getNowCookie().userInfo && boolean) return;
                this.$http({ // 保存用户播放信息
                    method: 'post',
                    url: '/studymodules-api/api/yyb/v1/expModule/api/savePlayAction',
                    data: {
                        id: row.id,
                        puserId: getNowCookie().userInfo.uid,
                    }
                }).then(res => {
                    console.log('res1', res.data.success);
                    // if(res.data.success) {
                    //     this.$message.success('操作成功.')
                    // }
                }).catch(error => {
                    console.log('error', error)
                })
            },
            linkTologin() {
                this.$router.push({ 
                    name: 'login',
                })
            },
            linkToDetails(item) { // 专家具体文章
                this.$router.push({ 
                    name: 'expertDialog',
                    params: {
                        id: item.id
                    },
                    query:{
                        name: `${item.name}`, 
                        moduleId: this.$route.params.id
                    }
                });
            }
        }
    }
</script>

